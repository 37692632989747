import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { addYears, startOfDay } from 'date-fns';

import { CookieBanner, Section } from '../components';
import {
  Footer as FranchisingFooter,
  Header as FranchisingHeader
} from '../franchising/components';
import GlobalStyles from '../global';
// import GlobalStyles, { SEO } from '../global';
import { Footer, Header } from '../sections';

const Container = styled.div`
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

/**
 * Layout with a header, optional footer, cookie banner, and basic SEO
 */
const BaseLayout = ({
  children,
  className,
  footerDivider,
  footerNavigation,
  franchisingFooter,
  franchisingHeader,
  headerNavigation,
  orderNavigation,
  withoutFooter,
  ...seoProps
}) => {
  const [cookieClosing, setCookieClosing] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-TMCM7G'
  };

  const setCookieDate = () => {
    const date = new Date();
    localStorage.setItem('cookie-date', JSON.stringify(startOfDay(date)));
  };

  const acceptNotice = () => {
    localStorage.setItem('accepted-cookie', JSON.stringify(true));
    TagManager.initialize(tagManagerArgs);
    setCookieClosing(true);
    setCookieDate();
  };

  const closeNotice = () => {
    localStorage.setItem('accepted-cookie', JSON.stringify(false));
    setCookieClosing(true);
    setCookieDate();
  };

  useEffect(() => {
    if (cookieClosing) {
      // wait for the banner to animate out before closing
      setTimeout(() => {
        setCookieClosing(false);
        setShowCookieBanner(false);
      }, 2000);
    }
  }, [cookieClosing]);

  useEffect(() => {
    if (window) {
      // check if user has made a decision on the cookie in the past year
      const cookieDate = localStorage.getItem('cookie-date');
      const oneYearAgoToday = JSON.stringify(
        startOfDay(addYears(new Date(), -1))
      );

      if (cookieDate && cookieDate < oneYearAgoToday) {
        localStorage.removeItem('cookie-date');
        setShowCookieBanner(true);
      }

      // check if the browser should hide the cookie notice or not
      const cookieSelection = localStorage.getItem('accepted-cookie');
      if (!cookieSelection || cookieSelection == null) {
        setShowCookieBanner(true);
      } else if (cookieSelection === 'true') {
        TagManager.initialize(tagManagerArgs);
      }
    }
  }, []);

  return (
    <Container className={className}>
      {/* Temporarily commenting out GTM-generating code to help client test something: */}
      {/* <SEO {...seoProps} /> */}

      <GlobalStyles />

      {franchisingHeader ? (
        <FranchisingHeader menu={franchisingHeader} />
      ) : (
        <Header
          headerNavigation={headerNavigation}
          orderNavigation={orderNavigation}
        />
      )}

      <>
        {children}

        {!withoutFooter && (
          <Section
            align="left"
            bgColor="tan"
            dividerTopColor={footerDivider ? 'white' : null}
            dividerTopReverse
            halfPadding
            unContain
          >
            {franchisingFooter ? (
              <FranchisingFooter menu={franchisingFooter} />
            ) : (
              <Footer menu={footerNavigation} />
            )}
          </Section>
        )}
        {showCookieBanner && (
          <CookieBanner
            acceptNotice={acceptNotice}
            closeNotice={closeNotice}
            closing={cookieClosing}
          />
        )}
      </>
    </Container>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footerDivider: PropTypes.bool,
  footerNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  franchisingFooter: PropTypes.arrayOf(PropTypes.object),
  franchisingHeader: PropTypes.arrayOf(PropTypes.object),
  headerNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderNavigation: PropTypes.arrayOf(PropTypes.object),
  withoutFooter: PropTypes.bool
};

export default BaseLayout;
